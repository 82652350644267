import RegisterForm from './RegisterForm';
import { useTranslation } from 'react-i18next';
import ArrowDown from "../assets/images/arrow-down.svg";

const HomeMega = () => {
	const { t } = useTranslation();

    return (
        <div className='w-full lg:grid lg:grid-cols-2 bg-gray-800'>
            <div className="h-full">
                <div className='text-white flex flex-col items-center lg:items-start justify-center gap-4 lg:gap-8 p-8 lg:p-16 w-full h-full max-h-screen max-lg:pt-28'>
                    <p className='font-poppins max-lg:text-center text-lg lg:text-2xl'>
                        { process.env.REACT_APP_DESCRIPTION }
                    </p>
                    <p className="relative bg-white bg-opacity-5 p-4 rounded-md text-center lg:hidden text-lg pb-10 border-2 border-white">
                        <strong>Chegou a hora!</strong> Preencha o formulário abaixo e em menos de um minuto você estará a um passo de conquistar sua vaga na <i>maior Agência de Modelos da América Latina</i>.
                        <img className="w-12 h-12 absolute left-1/2 -bottom-6 -ml-6 bg-slate-900 rounded-full animate-bounce" src={ArrowDown} alt="Faça Parte!" />
                    </p>
                </div>
            </div>
            <div className="max-lg:py-8 w-full flex flex-col p-4 md:p-8 lg:p-16 gap-2 lg:gap-4 items-center justify-center bg-gray-200 lg:pt-28" id="subscribe">
                <h2 className="mb-4 text-5xl text-center w-full font-poppins font-bold text-black">{ t('subscribe') }</h2>
                <div className="bg-white rounded p-8 w-full md:shadow-lg">
                    <RegisterForm />
                </div>
            </div>
        </div>
    )
}

export default HomeMega